import React, { useRef } from 'react';
import { uploadCSV, downloadCSV } from '../services/api';

const CSVManager = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUpload = async () => {
    if (fileInputRef.current?.files) {
      await uploadCSV(fileInputRef.current.files[0]);
    }
  };

  const handleDownload = async () => {
    const response = await downloadCSV();
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'events.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} />
      <button onClick={handleUpload}>Upload CSV</button>
      <button onClick={handleDownload}>Download CSV</button>
    </div>
  );
};

export default CSVManager;
