import axios from 'axios';
import { BikeEvent } from '../models/eventModel';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api/events';

export const getEvents = () => axios.get<BikeEvent[]>(API_URL);
export const getEventById = (id: number) => axios.get<BikeEvent>(`${API_URL}/${id}`);
export const createEvent = (event: BikeEvent) => axios.post<BikeEvent>(API_URL, event);
export const updateEvent = (id: number, event: BikeEvent) => axios.put<BikeEvent>(`${API_URL}/${id}`, event);
export const deleteEvent = (id: number) => axios.delete(`${API_URL}/${id}`);
export const uploadCSV = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${API_URL}/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};
export const downloadCSV = async (): Promise<Blob> => {
    const response = await axios.get(`${API_URL}/events/export`, {
      responseType: 'blob',
    });
    return response.data as Blob;
  };