import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { BikeEvent } from '../models/eventModel';
import { getEvents, deleteEvent } from '../services/api';

const EventList = ({ setSelectedEvent }: { setSelectedEvent: (event: BikeEvent | null) => void }) => {
  const [events, setEvents] = useState<BikeEvent[]>([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const response = await getEvents();
    setEvents(response.data);
  };

  const handleDelete = async (id: number) => {
    await deleteEvent(id);
    fetchEvents();
  };

  const formatDate = (dateStr: string) => format(new Date(dateStr), 'dd.MM.yyyy');

  return (
    <div>
      <h2>Bike Events</h2>
      <ul>
        {events.map(event => (
          <li key={event.id}>
            <p>Name: {event.name}</p>
            <p>Start Date: {formatDate(event.start_date)}</p>
            <p>End Date: {formatDate(event.end_date)}</p>
            <p>Registration Start Date: {formatDate(event.registration_start_date)}</p>
            <p>Registration End Date: {formatDate(event.registration_end_date)}</p>
            <p>Distance: {event.distance_km} km</p>
            <p>Elevation: {event.elevation_m} m</p>
            <p>Start Location: {event.start_location}</p>
            <p>End Location: {event.end_location}</p>
            <p>Website: <a href={event.web_url}>{event.web_url}</a></p>
            <button onClick={() => setSelectedEvent(event)}>Edit</button>
            <button onClick={() => handleDelete(event.id!)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EventList;
