import React, { useState } from 'react';
import EventList from './components/EventList';
import EventForm from './components/EventForm';
import CSVManager from './components/CSVManager';
import { BikeEvent } from './models/eventModel';

function App() {
  const [selectedEvent, setSelectedEvent] = useState<BikeEvent | null>(null);

  return (
    <div>
      <h1>Bike Events Manager</h1>
      <button onClick={() => setSelectedEvent(null)}>Create New Event</button>
      {selectedEvent ? (
        <EventForm event={selectedEvent} fetchEvents={() => {}} setSelectedEvent={setSelectedEvent} />
      ) : (
        <EventList setSelectedEvent={setSelectedEvent} />
      )}
      <CSVManager />
    </div>
  );
}

export default App;
