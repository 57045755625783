import React, { useState } from 'react';
import { format } from 'date-fns';
import { BikeEvent } from '../models/eventModel';
import { createEvent, updateEvent } from '../services/api';

const initialEvent: BikeEvent = {
  name: '',
  start_date: '',
  end_date: '',
  registration_start_date: '',
  registration_end_date: '',
  distance_km: 0,
  elevation_m: 0,
  start_location: '',
  end_location: '',
  web_url: ''
};

const EventForm = ({ event, fetchEvents, setSelectedEvent }: { event: BikeEvent | null, fetchEvents: () => void, setSelectedEvent: (event: BikeEvent | null) => void }) => {
  const [formEvent, setFormEvent] = useState<BikeEvent>(event || initialEvent);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormEvent({ ...formEvent, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formEvent.id) {
      await updateEvent(formEvent.id, formEvent);
    } else {
      await createEvent(formEvent);
    }
    fetchEvents();
    setSelectedEvent(null);
  };

  const formatDate = (dateStr: string) => format(new Date(dateStr), 'yyyy-MM-dd');

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="name" value={formEvent.name} onChange={handleChange} placeholder="Event Name" />
      <input type="date" name="start_date" value={formatDate(formEvent.start_date)} onChange={handleChange} />
      <input type="date" name="end_date" value={formatDate(formEvent.end_date)} onChange={handleChange} />
      <input type="date" name="registration_start_date" value={formatDate(formEvent.registration_start_date)} onChange={handleChange} />
      <input type="date" name="registration_end_date" value={formatDate(formEvent.registration_end_date)} onChange={handleChange} />
      <input type="number" name="distance_km" value={formEvent.distance_km} onChange={handleChange} placeholder="Distance (km)" />
      <input type="number" name="elevation_m" value={formEvent.elevation_m} onChange={handleChange} placeholder="Elevation (m)" />
      <input type="text" name="start_location" value={formEvent.start_location} onChange={handleChange} placeholder="Start Location" />
      <input type="text" name="end_location" value={formEvent.end_location} onChange={handleChange} placeholder="End Location" />
      <input type="text" name="web_url" value={formEvent.web_url} onChange={handleChange} placeholder="Website URL" />
      <button type="submit">Save Event</button>
    </form>
  );
};

export default EventForm;
